var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "15vh",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("安排补课")])]
      ),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticStyle: { "margin-top": "30px" },
          attrs: {
            model: _vm.formInfo,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-form-item", { attrs: { label: "缺勤学员：" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.lessonData.stuName) +
                      " [" +
                      _vm._s(_vm.lessonData.stuLoginName) +
                      "]"
                  ),
                ]),
              ]),
              _c("el-form-item", { attrs: { label: "缺勤课次：" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.lessonData.courseTitle) +
                      "/第" +
                      _vm._s(_vm.lessonData.courseUnitSort) +
                      "课 " +
                      _vm._s(_vm.lessonData.courseUnitTitle)
                  ),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "补课方式：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.radioChange },
                      model: {
                        value: _vm.misslessonType,
                        callback: function ($$v) {
                          _vm.misslessonType = $$v
                        },
                        expression: "misslessonType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("新增补课"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("跟班补课"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.misslessonType === "2"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "补课课节：", prop: "curriculum" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.chooseCourseUnit },
                        },
                        [_vm._v("选择课节")]
                      ),
                      _vm.closeTag && _vm.formInfo.curriculum !== ""
                        ? _c(
                            "el-tag",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { closable: "", type: "info" },
                              on: { close: _vm.closeTagFun },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.formInfo.curriculum) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.misslessonType === "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上课时间：", prop: "lessonTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm",
                          format: "yyyy-MM-dd HH:mm",
                          placeholder: "选择补课的日期时间",
                        },
                        model: {
                          value: _vm.formInfo.lessonTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInfo, "lessonTime", $$v)
                          },
                          expression: "formInfo.lessonTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.misslessonType === "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "授课老师：", prop: "teacher" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请选择补课老师",
                          },
                          model: {
                            value: _vm.formInfo.teacher,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInfo, "teacher", $$v)
                            },
                            expression: "formInfo.teacher",
                          },
                        },
                        _vm._l(_vm.teacheroptions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn1",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v(" 确 定 ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v(" 取 消 ")]
          ),
        ],
        1
      ),
      _c("choosecurriculum", {
        attrs: {
          show: _vm.chooseCurriculumDialog,
          originStudent: _vm.lessonData,
        },
        on: {
          chooseCurriculum: _vm.chooseCurriculum,
          close: function ($event) {
            _vm.chooseCurriculumDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }