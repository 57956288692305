<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    @open="openDialog"
    width="50%"
    top="15vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>安排补课</span>
    </div>

    <el-form
      style="margin-top: 30px;"
      :model="formInfo"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
    >
      <el-row>
        <el-form-item label="缺勤学员：">
          <span>{{lessonData.stuName}} [{{lessonData.stuLoginName}}]</span>
        </el-form-item>
        <el-form-item label="缺勤课次：">
          <span>{{lessonData.courseTitle}}/第{{lessonData.courseUnitSort}}课 {{lessonData.courseUnitTitle}}</span>
        </el-form-item>
        <el-form-item label="补课方式：">
          <el-radio-group v-model="misslessonType" @change="radioChange">
            <el-radio label="1">新增补课</el-radio>
            <el-radio label="2">跟班补课</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="补课课节：" prop="curriculum" v-if="misslessonType === '2'">
          <el-button type="text" @click="chooseCourseUnit">选择课节</el-button>
          <el-tag
            closable
            v-if="closeTag && formInfo.curriculum !== ''"
            style="margin-left: 20px;"
            @close="closeTagFun"
            type="info"
          >
            {{formInfo.curriculum}}
          </el-tag>
        </el-form-item>
        <el-form-item label="上课时间：" prop="lessonTime" v-if="misslessonType === '1'">
          <el-date-picker
            v-model="formInfo.lessonTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            placeholder="选择补课的日期时间">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="授课老师：" prop="teacher" v-if="misslessonType === '1'">
          <el-select v-model="formInfo.teacher" filterable placeholder="请选择补课老师">
            <el-option
              v-for="item in teacheroptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row slot="footer">
      <el-button
        type="primary"
        @click="submitForm('ruleForm')"
        class="dialog-btn1"
      >
        确 定
      </el-button>
      <el-button @click="close" type="info" class="dialog-btn">
        取 消
      </el-button>
    </el-row>
    <choosecurriculum :show="chooseCurriculumDialog" :originStudent="lessonData" @chooseCurriculum="chooseCurriculum" @close="chooseCurriculumDialog = false"></choosecurriculum>
  </el-dialog>
</template>
<script>
import choosecurriculum from '@/components/education/choosecurriculum'

import {
  addMendLessons,
  addStudentLesson
} from "@/api/member/misslesson";
import {
  getEmployeeList
} from "@/api/member/employee";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    lessonData: {
      default: () => {
        return {}
      },
      type: Object
    },
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.ruleForm) {
          this.$refs.ruleForm.resetFields()
        }
      }
    }
  },
  components:{choosecurriculum},
  data() {
    return {
      chooseCurriculumDialog:false,
      closeTag:true,
      followLessonId:'',
      misslessonType:'1',
      teacheroptions: [],
      formInfo: {
        lessonTime:'',
        teacher:'',
        curriculum:''
      },
      rules: {
        curriculum: [{ required: true, message: "请选择课节", trigger: "blur" }],
        teacher: [{ required: true, message: "请选择补课老师", trigger: "blur" }],
        lessonTime: [{ required: true, message: "选择补课的日期时间", trigger: "blur" }],
      },
    };
  },
  methods: {
    radioChange() {
      this.$refs.ruleForm.resetFields()
    },
    openDialog(){
      this.misslessonType = '1'
      this.getEmployeeList()
    },
    async getEmployeeList() {
      const res = await getEmployeeList({
        pageNum: 1,
        pageSize: 10000,
        needCount: true,
      });
      this.teacheroptions = res.body.list;
    },
    chooseCurriculum(data) {
      this.closeTag = true
      this.followLessonId = data.lessonId
      this.lessonData.chooseLessonId = data.lessonId
      this.formInfo.curriculum = data.className + " " +data.startTime.substring(0,19)
    },
    chooseCourseUnit() {
      this.chooseCurriculumDialog = true
    },
    closeTagFun() {
      this.closeTag = false
      this.formInfo.curriculum = ''
    },
    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.misslessonType === '1') {
            addMendLessons({
              beMended: this.lessonData.lessonId,
              start_time: this.formInfo.lessonTime,
              teacherIds:[this.formInfo.teacher],
              courseUnitIds:[this.lessonData.courseUnitId],
              studentIds:[this.lessonData.userId]
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('新增排补课成功')
                this.close()
                this.$emit('updateView')
              }
            })
          }else {
            addStudentLesson({
              lessonId:this.followLessonId ,
              isMend: true,
              beMend:this.lessonData.lessonId,
              studentIds:[this.lessonData.userId]
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('新增排补课成功')
                this.close()
                this.$emit('updateView')
              }
            })
          }
        }
      });
    },
    close() {
      this.$emit('close')
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    initData() {
      this.resetForm("ruleForm");
      this.formInfo = {
        lessonTime:'',
        teacher:'',
        curriculum:'2021/12/08  18:00'
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/dialog";
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
/deep/ .el-dialog__body {
  padding-top: 0px;
}
.dialog-btn1 {
  width: 200px;
  height: 50px;
  margin-bottom: 38px;
  &:first-child {
    margin-left: 220px;
    margin-right: 38px;
  }
}
</style>
