<template>
  <div>
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-input
              placeholder="输入关键字"
              v-model="inputVal"
              @keyup.enter.native="searchTwo"
            >
              <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 110px;">
                <el-option label="登录名" value="1"></el-option>
                <el-option label="姓名" value="2"></el-option>
              </el-select>
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-col>
          <el-col :lg="4" :md="24">
            <el-form-item label="班级名称：">
              <el-input
                clearable
                v-model="className"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="4" :md="24">
            <el-form-item label="课节标题：">
              <el-input
                clearable
                v-model="courseUnitTitle"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :md="24">
            <el-form-item label="开班日期：">
              <date-picker ref="dataPicker" class="w100" @updateView="datetTimeGet"></date-picker>
            </el-form-item>
          </el-col>
          <span>
            <el-button @click="resetForm">重置</el-button>
            <el-button type="primary" @click="searchList">查询</el-button>
          </span>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center">
        <span>缺勤记录</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
          :data="tableData"
          style="max-height: calc(100vh - 270px); overflow-y: auto"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '操作'">
                <el-row v-if="scope.row.mendLesson">
                <el-button type="text" @click="previewMissonDetail(scope.row)">补课信息</el-button>
                  <el-button type="text" @click="undoLesson(scope.row)">撤销</el-button>
                </el-row>
                <el-row v-else>
                  <el-button type="text" @click="gotoLesson(scope.row)">排补课</el-button>
                </el-row>
              </template>
              <template v-else-if="item.prop === 'courseUnitSort'">
                <span>第{{scope.row.courseUnitSort}}课</span>
              </template>
              <template v-else-if="item.prop === 'startTime'">
                <span>{{scope.row.startTime.substring(0,19)}}</span>
              </template>
              <template v-else-if="item.prop === 'mendLesson'">
                <el-tag :type=" scope.row.mendLesson ? 'success' : 'danger'">
                  {{ scope.row.mendLesson ? '已排补课' : '待补课' }}
                </el-tag>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
    <!-- </el-card> -->
    <misson-lesson :show="misslessonDialog" :lessonData = 'missLessonData' @close="misslessonDialog = false" @updateView="listMendLessons"></misson-lesson>
    <misslesson-detail-modal ref="detail" title="查看" />
  </div>
</template>
<script>
import {
  listMendLessons,
  revokeLesson
} from "@/api/member/misslesson";
import datePicker from '@/components/date-picker'
import MisslessonDetailModal from "@/views/member/MisslessonDetailModal";
import missonLesson from "@/components/education/miss-lesson";
import TextButton from "@/components/TextButton";

// 默认展示列

const baseColSetting = [
  {
    label: "登录账号",
    prop: "stuLoginName",
    state: true,
    align: "left",
    // sortable: true,
    width: "100",
  },
  {
    label: "姓名",
    prop: "stuName",
    state: true,
    align: "center",
    width: "100",
  },

  {
    label: "所属班级",
    prop: "className",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "上课时间",
    prop: "startTime",
    state: true,
    sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "课次",
    prop: "courseUnitSort",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "课节标题",
    prop: "courseUnitTitle",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "补课记录",
    prop: "mendLesson",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "130",
  },
];
export default {
  components: { MisslessonDetailModal, TextButton,missonLesson ,datePicker},
  data() {
    return {
      inputVal:'',
      select:'1',
      minStartTime:'',
      maxStartTime:'',
      missLessonData:{},
      misslessonDialog:false,
      expand: false,
      size: "default",
      tableKey: 1,
      item: {},
      title: "",
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      courseUnitTitle:'',
      className:'',

      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.listMendLessons();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    datetTimeGet (data) {
      if (data === null) {
        this.minStartTime = ''
        this.maxStartTime = ''
      } else {
        this.minStartTime = data[0]
        this.maxStartTime = data[1]
      }
    },
    undoLesson(row) {
        this.$confirm("确定撤销已安排的补课？", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            revokeLesson({
              lessonId:row.lessonId,
              mendLessonId:row.mendLesson,
              studentId:row.userId,
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('撤销成功')
                this.listMendLessons();
              }
            })
          })
          .catch(() => {
            return false;
          });
    },
    gotoLesson(row) {
      this.missLessonData = row
      this.misslessonDialog = true
    },
    previewMissonDetail(row) {
      this.$refs.detail.getMendRecordMethod(row)
    },
    /**
     * 获取列表
         stuLoginName	学生登录名
       stuName	学生姓名
       className	班级名称
       courseTitle	课节标题
       st	查询开始时间
       et	查询结束时间
     */
    async listMendLessons() {
      let responseData = await listMendLessons({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        stuLoginName:this.select === '1'? this.inputVal : '',
        stuName:this.select === '2'? this.inputVal : '',
        et:this.maxStartTime,
        st:this.minStartTime,
        className:this.className,
        courseTitle:this.courseUnitTitle
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    searchList(){
      this.pageInfo.pageNum = 1;
      this.listMendLessons();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.listMendLessons();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const { userId, state } = item;
      setEnable({
        userId,
        status: state == "1" ? 3 : 1,
      }).then(() => {
        this.listMendLessons();
      });
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.courseUnitTitle = ''
      this.className = ''
      this.minStartTime = ''
      this.maxStartTime = ''
      this.inputVal = ''
      this.select = '1'
      // this.listMendLessons();
    },
    /**
     * 查看
     */
    handleClick(item) {},
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.listMendLessons();
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.listMendLessons();
    },
    /**
     * 打开详情
     */
    openDetailModal(item) {
      this.$refs.detail.initData(item);
      this.$nextTick(() => {
        this.$refs.detail.drawer = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/container.scss";
@import "../../style/table.scss";

.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
/deep/.el-card__body {
  padding-bottom: 0px;
}

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
