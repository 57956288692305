<template>
  <el-drawer
    title="补课信息"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
  >
    <div style="padding: 0 10px">
      <el-row>
        <el-col class="item-title">学员：{{missLessonDetail.studentName}} [{{missLessonDetail.studentLoginName}}]</el-col>
        <el-col class="item-title">补课内容：{{missLessonDetail.courseTitle}}/第{{missLessonDetail.courseUnitSort}}课 {{missLessonDetail.courseUnitTile}}</el-col>
        <el-col class="item-title">补课班级：{{ missLessonDetail.className }}</el-col>
        <el-col class="item-title">上课时间：{{ missLessonDetail.startTime.substring(0,19)}}</el-col>
        <el-col class="item-title">授课老师：{{ missLessonDetail.teacherName }}</el-col>
        <el-col class="item-title">上课状态：{{ missLessonDetail.finishedStatus }}</el-col>
        <el-col class="item-title">出勤状态：{{ missLessonDetail.isAttend === '否' ? '未出勤' : '已出勤' }}</el-col>
      </el-row>
    </div>
  </el-drawer>
</template>
<script>
  import {
    getMendRecord,
  } from "@/api/member/misslesson";
export default {
  data() {
    return {
      drawer: false,
      direction: "rtl",
      missLessonDetail: {},
    };
  },
  methods: {
    async getMendRecordMethod(lessonData) {
      let responseData = await getMendRecord({
        mendLessonId:lessonData.mendLesson,
        studentId:lessonData.userId,
      });
      if (responseData && responseData.state == "success") {
        if(responseData.body) {
          this.missLessonDetail = responseData.body
          this.drawer = true
        }else {
          this.drawer = false
          window.$msg('暂无补课信息',2)
        }
      }
    },
    handleClose() {
      this.drawer = false;
    },
    initData(item) {
      this.item = { ...item };
    },
  },
};
</script>
<style lang="scss" scoped>
.item-title {
  height: 50px;
}
</style>
