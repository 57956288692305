<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span style="font-size: 16px;">选择课节</span>
    </div>
    <el-row style="margin-top: 25px;">
      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
      >
        <el-table-column label="班级名称" align="center" prop="className" ></el-table-column>
        <el-table-column label="上课时间" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.startTime.substring(0,19)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" v-if="originStudent.chooseLessonId !== scope.row.lessonId" @click="chooseStudent(scope.row)">选择</el-button>
            <el-button type="success" size="mini"  v-if="originStudent.chooseLessonId === scope.row.lessonId">已选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-row>
  </el-dialog>
</template>

<script>
import {listOptionalMendLessons} from "@/api/member/misslesson";
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    originStudent: {
      default: {},
      type: Object
    },
  },
  data () {
    return {
      dataList: [],
      nameorcard:"",
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  methods: {
    chooseStudent(row){
      this.$emit("chooseCurriculum",row)
      this.close()
    },
    openDialog () {
      this.nameorcard = ""
      this.listOptionalMendLessons();
    },

    async listOptionalMendLessons () {
      const res = await listOptionalMendLessons({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        lessonId:this.originStudent.lessonId
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    close () { this.$emit('close') },

    search(){
      this.pagination.currentPage = 1
      this.listOptionalMendLessons()
    },
    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.listOptionalMendLessons()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.listOptionalMendLessons()
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
@import "../../../assets/font/iconfont.css";
.centerCls {
  display: flex;
  align-items: center
}
p {
  line-height: 30px;
}
</style>
