import $axios from '@/utils/request'


/**
 * 展示学生补课列表
 * @param {*} data
 * @returns
 */
export function listMendLessons(data) {
    const url = '/merchant/lesson/mend/listMendLessons'
    return $axios.fPost(url, data)
}

/**
 * 查看补课排课记录
 * @param {*} data
 * @returns
 */
export function getMendRecord(data) {
  const url = '/merchant/lesson/mend/getMendRecord'
  return $axios.fPost(url, data)
}

/**
 * 撤销补课
 * @param {*} data
 * @returns
 */
export function revokeLesson(data) {
  const url = '/merchant/lesson/mend/revoke'
  return $axios.fPost(url, data)
}

/**
 * 展示跟班补课可选课节列表
 * @param {*} data
 * @returns
 */
export function listOptionalMendLessons(data) {
  const url = '/merchant/lesson/mend/listOptionalMendLessons'
  return $axios.fPost(url, data)
}

/**
 * 新增排补课
 * @param {*} data
 * @returns
 */
export function addMendLessons(data) {
  const url = '/merchant/lesson/mend/add'
  return $axios.fPost(url, data)
}

/**
 * 跟班排补课
 * @param {*} data
 * @returns
 */
export function addStudentLesson(data) {
  const url = '/merchant/lesson/addStudent'
  return $axios.fPost(url, data)
}

